<template>
    <component-slide-in :class="{ active }" @close="close">

        <template v-if="!loading.CHANNEL_UPDATE && !loading.CHANNEL_GET && Object.keys(channel).length > 0">
            <div class="d-flex align-items-center mb-3">
                <component-avatar>{{ channel.channelDescShort.substr(0, 1).toUpperCase() }}</component-avatar>
                <component-slide-in-title :editable="false"
                                          :subtitle="channel.channelDescLong"
                                          :title="channel.channelDescShort"
                                          :uuid="channel.channelUniqueId"
                                          class="ms-3 flex-grow-1"/>
            </div>

            <nav class="nav nav-tabs mb-3">
                <button id="nav-general-tab"
                        aria-controls="nav-general"
                        aria-selected="false"
                        class="nav-link active"
                        data-bs-target="#nav-general"
                        data-bs-toggle="tab"
                        role="tab"
                        type="button">{{ $t('general') }}
                </button>
                <button id="nav-references-tab"
                        aria-controls="nav-references"
                        aria-selected="false"
                        class="nav-link"
                        data-bs-target="#nav-references"
                        data-bs-toggle="tab"
                        role="tab"
                        type="button">{{ $tc('reference', 2) }}
                </button>
                <button id="nav-home-tab"
                        aria-controls="nav-comments"
                        aria-selected="true"
                        class="nav-link"
                        data-bs-target="#nav-comments"
                        data-bs-toggle="tab"
                        role="tab"
                        type="button">{{ $tc('comment', 2) }}
                </button>
                <button id="nav-activity-tab"
                        aria-controls="nav-activity"
                        aria-selected="false"
                        class="nav-link"
                        data-bs-target="#nav-activity"
                        data-bs-toggle="tab"
                        role="tab"
                        type="button">{{ $tc('activity', 1) }}
                </button>
            </nav>

            <div id="nav-tabContent" class="tab-content">
                <div id="nav-general" aria-labelledby="nav-general-tab" class="tab-pane fade show active"
                     role="tabpanel">
                    <form @submit.prevent="onUpdateChannel">
                        <div class="row">
                            <div class="col-6">
                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="channel-desc-short">{{ $t('short-name') }}</label>
                                    <input id="channel-desc-short"
                                           v-model="descShort"
                                           :aria-label="$t('short-name')"
                                           :class="{'is-invalid': this.$v.descShort.$invalid}"
                                           :disabled="!edit.flag"
                                           aria-describedby="channel-desc-short"
                                           class="form-control form-control-sm"
                                           type="text">
                                    <div v-if="!this.$v.descShort.required" class="invalid-feedback">
                                        {{ $t('validation.error.required.desc-short') }}
                                    </div>
                                    <div v-if="!this.$v.descShort.maxLength" class="invalid-feedback">
                                        {{
                                            $t('validation.error.maxLength.desc-short', {count: $v.descShort.$params.maxLength.max})
                                        }}
                                    </div>
                                </div>
                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="channel-desc-long">{{ $tc('name', 1) }}</label>
                                    <input id="channel-desc-long"
                                           v-model="descLong"
                                           :aria-label="$tc('name', 1)"
                                           :class="{'is-invalid': this.$v.descLong.$invalid}"
                                           :disabled="!edit.flag"
                                           aria-describedby="channel-desc-long"
                                           class="form-control form-control-sm"
                                           type="text">
                                    <div v-if="!this.$v.descLong.maxLength" class="invalid-feedback">
                                        {{
                                            $t('validation.error.maxLength.desc-long', {count: $v.descLong.$params.maxLength.max})
                                        }}
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label small"
                                           for="channel-comment">{{ $tc('description', 1) }}</label>
                                    <textarea id="channel-comment"
                                              v-model="comment"
                                              :aria-label="$tc('last-name', 1)"
                                              :class="{'is-invalid': this.$v.comment.$invalid}"
                                              :disabled="!edit.flag"
                                              aria-describedby="last-name"
                                              class="form-control form-control-sm"
                                              rows="6"
                                              type="text"></textarea>
                                    <div v-if="!this.$v.comment.maxLength" class="invalid-feedback">
                                        {{
                                            $t('validation.error.maxLength.comment', {count: $v.comment.$params.maxLength.max})
                                        }}
                                    </div>
                                </div>

                                <p>
                                    <button v-if="!edit.flag"
                                            class="btn btn-sm btn-outline-primary me-2"
                                            type="button"
                                            @click="onClickEdit">
                                        {{ $t('actions.edit') }}
                                    </button>
                                    <button v-if="edit.flag"
                                            :disabled="this.$v.$invalid"
                                            class="btn btn-sm btn-outline-success me-2">
                                        {{ $t('actions.update') }}
                                    </button>
                                    <button v-if="edit.flag"
                                            class="btn btn-sm btn-outline-danger"
                                            type="button"
                                            @click="edit.flag = false">
                                        {{ $t('actions.cancel') }}
                                    </button>
                                </p>
                            </div>
                            <div class="col-6">
                                <div class="mb-1">
                                    <span class="d-inline-block small mb-2">{{ $tc('type', 1) }}</span>
                                    <span class="disabled-input">{{ $t('types.channel.' + channel.channelType) }}</span>
                                </div>
                                <div class="mb-1">
                                    <span class="d-inline-block small mb-2">{{ $tc('status', 1) }}</span>
                                    <span class="disabled-input">{{
                                            $t('statuses.channel.' + channel.channelStatus)
                                        }}</span>
                                </div>
                                <div class="mb-1">
                                    <span class="d-inline-block small mb-2">{{ $tc('service-provider', 1) }}</span>
                                    <span class="disabled-input">{{
                                            channel.serviceProvider.serviceProviderDescLong
                                        }}</span>
                                </div>
                                <div class="mb-1">
                                    <span class="d-inline-block small mb-2">{{ $tc('service', 1) }}</span>
                                    <span class="disabled-input">{{
                                            channel.serviceProviderService.serviceProviderServiceDescLong
                                        }}</span>
                                </div>
                                <div v-if="serviceProviderServiceDescShort === 'MBOX'" class="mb-1">
                                    <span class="d-inline-block small mb-2">{{
                                            $t('pages.campaign-settings.authentication-key')
                                        }}</span>
                                    <span class="disabled-input">{{ channel.mboxToken }}</span>
                                </div>
                                <div v-if="serviceProviderServiceDescShort === 'MBOX'" class="mb-1">
                                    <span class="d-inline-block small mb-2">{{ $tc('email', 1) }}</span>
                                    <span class="disabled-input">{{ channel.mboxEmail }}</span>
                                </div>
                                <div v-if="serviceProviderServiceDescShort === 'MBOX'" class="mb-1">
                                    <span class="d-inline-block small mb-2">{{
                                            $t('data-processing-server-partner')
                                        }}</span>
                                    <span class="disabled-input">IONOS</span>
                                </div>
                                <div v-if="serviceProviderServiceDescShort === 'MBOX'" class="mb-1">
                                    <span class="d-inline-block small mb-2">{{
                                            $t('data-processing-server-location')
                                        }}</span>
                                    <span class="disabled-input">{{ countries.DE.name }}</span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div id="nav-references" aria-labelledby="nav-references-tab" class="tab-pane fade" role="tabpanel">
                    <div class="table-responsive">
                        <table class="table">
                            <tbody>
                            <tr>
                                <td class="border-bottom-0" colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">{{ $t('no-references-found') }}</div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="nav-comments" aria-labelledby="nav-contact-tab" class="tab-pane fade" role="tabpanel">
                    <div class="table-responsive">
                        <table class="table">
                            <tbody>
                            <tr>
                                <td class="border-bottom-0" colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">{{ $t('no-comments-found') }}</div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="nav-activity" aria-labelledby="nav-activity-tab" class="tab-pane fade" role="tabpanel">
                    <div class="list-group">
                        <div v-if="channel.meta_update_dt" class="list-group-item">
                            <div class="d-flex w-100 justify-content-between align-items-center">
                                <p class="mb-0">{{ $t('activity-logs.channel-updated') }}</p>
                                <small>{{ channel.meta_update_dt | timestamp }}</small>
                            </div>
                        </div>
                        <div class="list-group-item">
                            <div class="d-flex w-100 justify-content-between align-items-center">
                                <p class="mb-0">{{ $t('activity-logs.channel-created') }}</p>
                                <small>{{ channel.meta_insert_dt | timestamp }}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </template>

        <div v-else>
            <div class="d-flex align-items-center mb-3">
                <component-shimmer class="rounded-circle d-block me-3" height="51.2px" width="54.4px"/>
                <div>
                    <component-shimmer class="mb-1"/>
                    <component-shimmer width="241.8px"/>
                </div>
            </div>

            <div class="d-flex mb-3">
                <component-shimmer class="me-2" height="37.2px" width="25%"/>
                <component-shimmer class="me-2" height="37.2px" width="25%"/>
                <component-shimmer class="me-2" height="37.2px" width="25%"/>
                <component-shimmer class="me-2" height="37.2px" width="25%"/>
            </div>

            <component-shimmer height="35.2px" style="margin-bottom: 1px;" width="100%"/>
            <component-shimmer height="35.2px" style="margin-bottom: 1px;" width="100%"/>
            <component-shimmer height="35.2px" style="margin-bottom: 1px;" width="100%"/>
            <component-shimmer height="35.2px" style="margin-bottom: 1px;" width="100%"/>
            <component-shimmer height="35.2px" style="margin-bottom: 1px;" width="100%"/>
            <component-shimmer height="35.2px" style="margin-bottom: 1px;" width="100%"/>
            <component-shimmer height="35.2px" style="margin-bottom: 1px;" width="100%"/>
            <component-shimmer height="35.2px" style="margin-bottom: 1px;" width="100%"/>
            <component-shimmer height="35.2px" style="margin-bottom: 1px;" width="100%"/>

        </div>

    </component-slide-in>
</template>

<script>
import ComponentSlideIn from "@/components/ui/ComponentSlideIn";
import ComponentAvatar from "@/components/ui/ComponentAvatar";
import ComponentShimmer from "@/components/ComponentShimmer";
import ComponentSlideInTitle from "@/components/ui/ComponentSlideInTitle";
import {maxLength, required} from "vuelidate/lib/validators";
import {countries} from "countries-list";

export default {
    name: "ComponentUpdateChannel",
    components: {
        ComponentSlideInTitle,
        ComponentShimmer,
        ComponentAvatar,
        ComponentSlideIn
    },
    data() {
        return {
            edit: {
                flag: false,
                descShort: '',
                descLong: '',
                comment: ''
            },
            countries
        }
    },
    computed: {
        v() {
            return this.$v;
        },
        loading() {
            return {
                CHANNEL_GET: this.$store.state.loading.CHANNEL_GET,
                CHANNEL_UPDATE: this.$store.state.loading.CHANNEL_UPDATE
            }
        },
        channel() {
            return this.$store.state.CHANNEL.channel;
        },
        descShort: {
            get() {
                return this.edit.flag ? this.edit.descShort : this.channel.channelDescShort;
            },
            set(channelDescShort) {
                this.edit.descShort = channelDescShort;
            }
        },
        descLong: {
            get() {
                return this.edit.flag ? this.edit.descLong : this.channel.channelDescLong;
            },
            set(channelDescLong) {
                this.edit.descLong = channelDescLong;
            }
        },
        comment: {
            get() {
                return this.edit.flag ? this.edit.comment : this.channel.channelComment;
            },
            set(channelComment) {
                this.edit.comment = channelComment;
            }
        },
        serviceProviderServiceDescShort() {
            return this.channel.serviceProviderService.serviceProviderServiceDescShort;
        },
    },
    props: {
        active: {
            Type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        close() {
            this.$store.commit('backdrop/hide');
            this.$emit('close');
        },
        onUpdateChannel() {
            this.$store.dispatch('CHANNEL/UPDATE', {
                channelUniqueId: this.channel.channelUniqueId,
                channelDescShort: this.descShort,
                channelDescLong: this.descLong,
                channelComment: this.comment,
                channelType: this.channel.channelType,
                channelStatus: this.channel.channelStatus,
                serviceProviderServiceUniqueId: this.channel.serviceProviderService.serviceProviderServiceUniqueId,
                mboxEmail: this.channel.mboxEmail
            })
                .then(channel => {
                    this.edit.flag = false;
                    this.$emit('update', channel);
                    this.$gtag.event('CHANNEL_UPDATE');
                })
                .catch(error => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: error.response.data.message
                    });
                })
        },
        onClickEdit() {
            this.edit.descShort = this.channel.channelDescShort;
            this.edit.descLong = this.channel.channelDescLong;
            this.edit.comment = this.channel.channelComment;
            this.edit.flag = true;
        },
        closeOnEscape(e) {
            if (e.key === 'Escape' && this.active === true) {
                this.close();
            }
        }
    },
    validations: {
        descShort: {
            maxLength: maxLength(50),
            required
        },
        descLong: {
            maxLength: maxLength(255)
        },
        comment: {
            maxLength: maxLength(4000)
        },
    },
    watch: {
        active(newValue) {
            if (newValue === true) {
                window.addEventListener('keydown', this.closeOnEscape);
            } else {
                window.removeEventListener('keydown', this.closeOnEscape);
            }
        }
    },
}
</script>

<style scoped>

</style>
