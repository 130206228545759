<template>
    <layout-full-width :title="$tc('channel', 2)">
        <component-actions :action-filters="[]"
                           :bulk="bulk"
                           :general-search-fields="generalSearchFields"
                           :itemsPerPage="itemsPerPage"
                           :page="page"
                           :primary-actions="[]"
                           :total="total"
                           @applyFilters="onApplyFilters"
                           @changeItemsPerPage="onChangeItemsPerPage"
                           @paginate="onChangePage"
                           @refresh="listChannels"
                           @search="onSearch"/>

        <div class="row">
            <div class="col-12">
                <div class="table-responsive">
                    <table :class="{'table-hover': !loading.CHANNEL_LIST}" class="table">
                        <thead>
                        <component-table-header :heading-columns="headingColumns"
                                                :select-all-status="getSelectAllStatus()"
                                                :show-checkbox="true"
                                                @sort="onSortChannels"
                                                @toggleCheckbox="toggleCheckbox"/>
                        </thead>
                        <tbody>
                        <template v-cloak v-if="!loading.CHANNEL_LIST && channels.list.length > 0">
                            <tr v-for="(channel, i) in channels.list"
                                :key="'channel-' + i"
                                class="pointer"
                                @click="onClickChannel($event, channel)">
                                <td class="align-middle">
                                    <button class="btn btn-checkbox" @click="toggleCheckbox(i)">
                                        <font-awesome-icon
                                            :class="channel.selected ? 'text-primary' : ''"
                                            :icon="channel.selected ? ['fas', 'check-square'] : ['far', 'square']"
                                            class="pe-none"/>
                                    </button>
                                </td>
                                <td class="align-middle">
                                    <strong class="me-1">{{ $tc('name', 1) }}:</strong>
                                    <span>{{ channel.channelDescShort }}</span>
                                    <br>
                                    <strong class="me-1">{{ $tc('service-provider') }}:</strong>
                                    <span>{{
                                            channel.serviceProvider.serviceProviderDescLong
                                        }} &middot; {{
                                            channel.serviceProviderService.serviceProviderServiceDescLong
                                        }}</span>
                                </td>
                                <td class="align-middle">
                                    <span :class="getChannelTypeClass(channel.channelType)" class="badge">
                                        {{ $t('types.channel.' + channel.channelType) }}
                                    </span>
                                </td>
                                <td class="align-middle">
                                    <span :class="getChannelStatusClass(channel.channelStatus)" class="badge">
                                        {{ $t('statuses.channel.' + channel.channelStatus) }}
                                    </span>
                                </td>
                                <td class="align-middle">
                                    <template v-if="channel.meta_update_dt">
                                        {{ channel.meta_update_dt | timestamp }}
                                    </template>
                                    <template v-else>
                                        {{ channel.meta_insert_dt | timestamp }}
                                    </template>
                                </td>
                                <td class="align-middle">
                                    <!--
                                    <div class="dropdown">
                                        <button class="btn p-0 bg-transparent"
                                                type="button"
                                                :id="'channel-actions-' + i"
                                                data-bs-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false">
                                            <font-awesome-icon icon="ellipsis-h" class="pe-none"/>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right"
                                             :aria-labelledby="'dropdown-' + i">
                                            <button class="dropdown-item"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#modal-delete-channel"
                                                    @click="onClickDeleteChannel(channel)">
                                                {{ $t('actions.delete') }}
                                            </button>
                                        </div>
                                    </div>
                                    -->
                                </td>
                            </tr>
                        </template>
                        <template v-cloak v-if="!loading.CHANNEL_LIST && channels.list.length === 0">
                            <tr>
                                <td colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">{{ $t('pages.channels.no-channels-found') }}</div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-if="loading.CHANNEL_LIST">
                            <tr v-for="n in channels.itemsPerPage" :key="'row-shimmer-' + n">
                                <td class="align-middle">
                                    <component-shimmer height="14px" style="margin: 4px 0;" width="14px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer style="margin-bottom: 3px;" width="200px"/>
                                    <component-shimmer/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="60px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="50px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="105px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="20px"/>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <component-create-channel @create="onCreateChannel"/>

        <component-delete-channel :channel-desc-short="this.delete.channelDescShort"
                                  :channel-unique-id="this.delete.channelUniqueId"
                                  @delete="onDeleteChannel"/>

        <component-update-channel :active="update.active" @close="update.active = false" @update="onUpdateChannel"/>

    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";
import ComponentActions from "@/components/ui/ComponentActions";
import ComponentCreateChannel from "@/components/ComponentCreateChannel";
import ComponentDeleteChannel from "@/components/ComponentDeleteChannel";
import ComponentUpdateChannel from "@/components/ComponentUpdateChannel";
import ComponentShimmer from "@/components/ComponentShimmer";
import shouldOpenSlideIn from "@/lib/should-open-slide-in";
import ComponentTableHeader from '@/components/ui/ComponentTableHeader.vue';

export default {
    name: "PageChannels",
    components: {
        ComponentShimmer,
        ComponentUpdateChannel,
        ComponentDeleteChannel,
        ComponentCreateChannel,
        ComponentActions,
        LayoutFullWidth,
        ComponentTableHeader
    },
    computed: {
        loading() {
            return {
                CHANNEL_LIST: this.$store.state.loading.CHANNEL_LIST
            }
        },
        channels() {
            return this.$store.state.CHANNEL.channels;
        }
    },
    data() {
        return {
            bulk: false,
            filter: {},
            order: {meta_insert_dt: "DESC"},
            generalSearchFields: ['gen_channel_desc_short'],
            page: 1,
            itemsPerPage: 25,
            total: 0,
            headingColumns: [
                {
                    title: this.$tc('channel', 1),
                    sortField: 'gen_channel_desc_short'
                },
                {
                    title: this.$tc('type', 1),
                    sortField: 'genType.gen_type_desc_short'
                },
                {
                    title: this.$tc('status', 1)
                },
                {
                    title: this.$t('modified')
                },
                {
                    title: ""
                }
            ],
            delete: {
                channelDescShort: '',
                channelUniqueId: '',
            },
            update: {
                active: false,
            }
        }
    },
    methods: {
        getChannelTypeClass(channelType) {
            return {
                'bg-info': channelType === 'CHANNEL_INBOUND',
                'bg-light': channelType === 'CHANNEL_OUTBOUND'
            }
        },
        getChannelStatusClass(channelStatus) {
            return {
                'bg-info': channelStatus === 'CHANNEL_ACTIVE',
                'bg-success': channelStatus === 'CHANNEL_INACTIVE'
            }
        },
        listChannels() {
            this.$store.dispatch('CHANNEL/LIST', {
                filter: this.filter,
                order: this.order,
                page: this.page,
                itemsPerPage: this.itemsPerPage
            })
                .then(channels => {
                    this.itemsPerPage = channels.itemsPerPage;
                    this.page = channels.page;
                    this.total = channels.total;
                });
        },
        onChangePage(page) {
            if (page <= 0) {
                return;
            }

            this.page = parseInt(page, 10);
            this.listChannels();
        },
        onSortChannels(order) {
            this.order = order;
            this.listChannels();
        },
        onSearch() {
            console.log('onSearch');
        },
        onChangeItemsPerPage(itemsPerPage) {
            if (this.itemsPerPage === itemsPerPage) {
                return;
            }

            this.page = 1;
            this.itemsPerPage = itemsPerPage;
            this.listChannels();
        },
        onApplyFilters(filters) {
            this.filter = filters;
            this.listChannels();
        },
        async toggleCheckbox(i) {
            if (i === 'ALL') {
                if (this.getSelectAllStatus()) {
                    this.channels.list.forEach(channel => channel.selected = false);
                } else {
                    this.channels.list.forEach(channel => channel.selected = true);
                }
            } else {
                this.channels.list[i].selected = !this.channels.list[i].selected;
            }

            this.$forceUpdate();
            this.getActionButton();
        },
        getSelectAllStatus() {
            const channelCount = this.channels.list.length;
            let selectedChannelCount = 0;
            this.channels.list.forEach(channel => {
                if (channel.selected) {
                    selectedChannelCount += 1;
                }
            })
            return channelCount === selectedChannelCount && channelCount !== 0;
        },
        getActionButton() {
            let selectedChannelCount = 0;
            this.channels.list.forEach(channel => {
                if (channel.selected) {
                    selectedChannelCount += 1;
                }
            })

            this.bulk = selectedChannelCount !== 0;
        },
        onCreateChannel() {
            const payload = {
                title: this.$tc('toast.success.success'),
                message: this.$tc('toast.success.create-channel')
            }
            this.$store.dispatch('toast/success', payload);
            this.listChannels();
        },
        onDeleteChannel() {
            const payload = {
                title: this.$tc('toast.success.success'),
                message: this.$tc('toast.success.delete-channel')
            }
            this.$store.dispatch('toast/success', payload);
            this.listChannels();
        },
        onUpdateChannel() {
            this.$store.dispatch('toast/success', {
                title: this.$tc('toast.success.success'),
                message: this.$tc('toast.success.update-channel')
            });
            this.listChannels();
        },
        onClickDeleteChannel(channel) {
            this.delete.channelDescShort = '';
            this.delete.channelUniqueId = '';
            this.delete.channelDescShort = channel.channelDescShort;
            this.delete.channelUniqueId = channel.channelUniqueId;
        },
        onClickChannel(event, channel) {
            if (!shouldOpenSlideIn(event.target)) {
                return;
            }

            this.$store.commit('backdrop/show');
            this.update.active = true;
            this.$store.dispatch('CHANNEL/GET', channel.channelUniqueId);
        }
    },
    beforeMount() {
        this.listChannels();
    }
}
</script>

<style lang="scss" scoped>
</style>
