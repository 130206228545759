<template>
    <component-modal id="modal-delete-channel"
                     ref="modalDeleteChannel"
                     :title="$tc('modal.delete-channel.title', 1)">
        <form v-if="!loading" @submit.prevent="onSubmit">
            <ul class="list-group list-group-flush">
                <li class="list-group-item text-center">
                    <strong>{{ channelDescShort }}</strong>
                </li>
            </ul>
            <div class="modal-footer">
                <button class="btn btn-light" type="button" @click="close">{{ $t('actions.cancel') }}</button>
                <button class="btn btn-primary" type="submit">{{ $t('actions.submit') }}</button>
            </div>
        </form>
        <component-spinner v-if="loading === true" class="py-5"/>
    </component-modal>
</template>

<script>
import ComponentModal from "@/components/ui/ComponentModal";
import ComponentSpinner from "@/components/ui/ComponentSpinner";
import closeModal from "@/lib/close-modal";

export default {
    name: "ComponentDeleteChannel",
    components: {ComponentSpinner, ComponentModal},
    props: {
        channelDescShort: {
            type: String,
            required: true
        },
        channelUniqueId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        close() {
            closeModal(this.$refs.modalDeleteChannel.$el);
        },
        onSubmit() {
            this.loading = true;

            this.$store.dispatch('CHANNEL/DELETE', this.channelUniqueId)
                .then(channel => {
                    this.close();
                    this.loading = false;
                    this.$emit('delete', channel);
                    this.$gtag.event('CHANNEL_DELETE');
                })
                .catch(error => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: error.response.data.message
                    });
                    this.loading = false;
                });
        }
    }
}
</script>

<style lang="scss" scoped></style>
