<template>
    <component-modal id="modal-create-channel"
                     ref="modalCreateChannel"
                     :title="$tc('modal.create-channel.title', 1)">
        <form v-if="!loading" @submit.prevent="onSubmit">
            <div class="modal-body">
                <div class="mb-2 col-sm-12">
                    <label class="form-label" for="name">{{ $tc('name', 1) }}</label>
                    <input id="name"
                           v-model="channel.channelDescShort"
                           :class="{'is-invalid': v.channel.channelDescShort.$error}"
                           class="form-control"
                           type="text">
                    <div v-if="!v.channel.channelDescShort.required" class="invalid-feedback">
                        {{ $t('validation.error.required.name') }}
                    </div>
                    <div v-if="!v.channel.channelDescShort.maxLength" class="invalid-feedback">
                        {{
                            $t('validation.error.maxLength.name', {count: v.channel.channelDescShort.$params.maxLength.max})
                        }}
                    </div>
                </div>
                <div class="mb-2 col-sm-12">
                    <label class="form-label" for="type">{{ $tc('type', 1) }}</label>
                    <select id="type"
                            v-model="channel.channelType"
                            :class="{'is-invalid': v.channel.channelType.$error}"
                            class="form-select">
                        <option :value="null">{{ $t('actions.please-select') }}</option>
                        <option value="CHANNEL_OUTBOUND">{{ $t('types.channel.CHANNEL_OUTBOUND') }}</option>
                    </select>
                    <div v-if="!v.channel.channelType.required" class="invalid-feedback">
                        {{ $t('validation.error.required.channelType') }}
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" type="submit">{{ $t('actions.submit') }}</button>
            </div>
        </form>
        <component-spinner v-if="loading === true" class="py-5"/>
    </component-modal>
</template>

<script>
import ComponentModal from "@/components/ui/ComponentModal";
import {maxLength, required} from 'vuelidate/lib/validators';
import ComponentSpinner from "@/components/ui/ComponentSpinner";
import closeModal from "@/lib/close-modal";

export default {
    name: "ComponentCreateChannel",
    components: {ComponentSpinner, ComponentModal},
    computed: {
        v() {
            return this.$v;
        }
    },
    data() {
        return {
            loading: false,
            channel: {
                channelDescShort: '',
                channelDescLong: null,
                channelComment: null,
                channelType: 'CHANNEL_OUTBOUND'
            }
        }
    },
    methods: {
        onSubmit() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;

            this.$store.dispatch('CHANNEL/CREATE', {
                channelDescShort: this.channel.channelDescShort,
                channelDescLong: this.channel.channelDescLong,
                channelComment: this.channel.channelComment,
                channelType: this.channel.channelType,
                serviceProviderServiceUniqueId: 'a15f5e02-6ec8-4a14-9fb2-d541d2d3c55a'
            })
                .then(channel => {
                    closeModal(this.$refs.modalCreateChannel.$el);
                    this.loading = false;
                    this.$emit('create', channel);
                    this.$gtag.event('CHANNEL_CREATE');
                })
                .catch(error => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: error.response.data.message
                    });
                    this.loading = false;
                });
        }
    },
    validations: {
        channel: {
            channelDescShort: {
                required,
                maxLength: maxLength(55)
            },
            channelDescLong: {
                maxLength: maxLength(255)
            },
            channelComment: {
                maxLength: maxLength(255)
            },
            channelType: {
                required
            }
        }
    }
}
</script>

<style scoped>

</style>
